<script lang="tsx">
import type { PropType, SlotsType } from 'vue'
import type { ComponentOverrideOptions } from '@core-types/components'

export type CoreUiDividerProps<T> = {
    label?: string
}

type CoreUiDividerSlots<T> = {
    default: {}
}

type ComponentOptions = {}

export function defineComponentCoreUiDivider<T>(options?: ComponentOverrideOptions<ComponentOptions, CoreUiDividerProps<T>, CoreUiDividerSlots<T>>) {
    return defineComponent(
        (props: CoreUiDividerProps<T>, ctx) => {
            return () => (
                <div class="sim-divider">
                    <div class="sim-divider__line" />

                    <span class="sim-divider__label">
                        {
                            renderSlot(ctx.slots.default, options?.slots?.default, {},
                                (
                                    <>
                                        {props.label}
                                    </>
                                )
                            )
                        }
                    </span>

                    <div class="sim-divider__line" />
                </div>
            )
        },
        {
            props: {
                label: {
                    type: String as PropType<CoreUiDividerProps<T>['label']>,
                    default: options?.props?.label?.default ?? undefined,
                    required: options?.props?.label?.required ?? false,
                },
            },
            slots: Object as SlotsType<CoreUiDividerSlots<T>>,
            emits: {

            },
        }
    )
}

export default defineComponentCoreUiDivider()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiDivider.scss" as *;

</style>
