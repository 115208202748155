<script lang="tsx">
import { defineComponentCoreUiDivider } from '@core/app/components/core/ui/CoreUiDivider.vue'

export default defineComponentCoreUiDivider({})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiDivider" as *;

@include label {
    color: $sim-c-primary-800;

    @include sim-text-small;
    @include sim-font-regular;
}

@include line {
    background-color: $sim-c-basic-400;
}

</style>
